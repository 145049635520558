@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Roboto:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.celeste {
  background-color: #8ce2d0;
  font-size: 1.3rem;
}

.stripes {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  grid: repeat(5, 150px)/repeat(10, 1fr);
  background: linear-gradient(170deg, #8ce2d0 30%, #05d5ff 56%, #a6ffcb 90%);
  transform: skewY(-11deg);
  transform-origin: 0;
  position: absolute;
  z-index: -1;
}

.stripes :nth-child(1) {
  grid-column: span 2;
  background: #63d8bf;
}

.stripes :nth-child(2) {
  grid-area: 3 / span 3 / auto / -1;
  background: #32c6a7;
}

.stripes :nth-child(3) {
  -ms-grid-row: 4;
  grid-row: 4;
  grid-column: span 5;
  background: #70fee0;
}

.stripes :nth-child(4) {
  grid-column: span 5;
  background: #4553ff;
}

.stripes :nth-child(5) {
  -ms-grid-row: 5;
  grid-row: 5;
  grid-column: span 2;
  background: #1fa2ff;
}

.stripes :nth-child(6) {
  grid-area: 3 / span 3 / auto / -5;
  background: #1fa2ff;
}

.stripes :nth-child(7) {
  -ms-grid-row: 7;
  grid-area: 7 / span 4 / 7 / -3;
  background: linear-gradient(150deg, #4553ff 23%, #05d5ff 70%);
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.row {
  background-color: white;
}

.intro-cont {
  padding: 1.5rem;
  margin-bottom: 3rem;
}

.introLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem;
}

.introLeft h2 {
  font-weight: 300;
}

.introPicture img {
  border-radius: 50%;
}

.introRight {
  padding: 2rem;
}

.introRight p {
  text-indent: 1rem;
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.centered {
  padding: 2rem;
}

.card {
  background: white;
  margin-bottom: 2em;
}

.card img {
  display: block;
  border: 0;
  width: 100%;
  height: auto;
}

.card a:hover {
  box-shadow: 3px 3px 8px #cccccc;
}

.card-content {
  padding: 1.4em;
}

.card-content h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 100;
}

.card-content p {
  font-size: 80%;
}

/* Flexbox stuff */
@media screen and (min-width: 40em) {
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70em;
  }
  .card {
    flex-grow: 0;
            flex-shrink: 1;
            flex-basis: calc(50% - 1em);
  }
}

@media screen and (min-width: 60em) {
  .card {
    flex-grow: 0;
            flex-shrink: 1;
            flex-basis: calc(33% - 1em);
  }
}
/*# sourceMappingURL=appStyles.css.map */
